import React from 'react'
import ServiceSidebard from './ServiceSidebard'
import details1d from '../../assets/images/services/services-details1d.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentd = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1d} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Controlling</span>
                            <h3>About this Service</h3>
                            <p>Internal reporting and costing can be a big challenge for organizations. Our CO (controlling) service is an important module of the system used for an organization's internal reporting. Controlling service provides supporting information to management for effective planning, reporting, and monitors the business operations of an organization. With the help of information provided by the CO module, that organization can make an effective management decision-making process</p>
                            <p>Deciding on a managerial level requires a system that plans, monitors, and reports the ongoing operations. This is also closely related to financial supervision as there is a constant data flow between the two modules FI and CO. The first step is categorizing your accounts as either revenue or expense. Expense accounts could be the cost of goods sold while revenue could be a sales account. The cost center can then be used to analyze overall costs in each department. This will create master data for overheads. Forecasting can also be performed if calculation is required for costs occurring during manufacturing. Finally, profitability can be gauged through standard reports in CO according to market segments such as sales, production, consumers, and materials.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Controlled monitoring</li>
                                            <li>Cost Element Accounting</li>
                                            <li>Cost Center Accounting</li>
                                            <li>Internal Orders</li>
                                            <li>Activity-Based Costing ( ABC)</li>
                                            <li>Product Cost Controlling</li>
                                            <li>Profitability Analysis</li>
                                            <li>Overhead Cost Controlling</li>
                                            <li>Profit Center Accounting</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Our CO Service includes the procedures to unite and observe the overheads needed for financial reporting and accounting. With the help of our CO Service, it is possible to plan, perform, monitor, and report the costs. It will provide you the capability of storing the works of reporting, planning, and following the operations of the organization. This includes handling and arranging the master data that covers the cost components, cost centers, functional area, internal orders, etc. We will provide you vital, precise, and effective accounting consultancy. </p>
                            <h3>Application Areas</h3>
                            <p>Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Banking Sector
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Insurance Companies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Information and technologies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Utilities
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Chemical
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Pharmaceuticals
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Retail
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Oil and Energy
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>CO</span></li>
                                <li><span>CO-PA</span></li>
                                <li><span>CO-PC</span></li>
                                
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebard />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentd