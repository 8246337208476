import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContentd from '../components/ServiceDetailsd/ServiceDetailsContentd'
import RelatedServicesd from '../components/ServiceDetailsd/RelatedServicesd'

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Controlling"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Service Details"
            />
            <ServiceDetailsContentd />
            <RelatedServicesd />
            <Footer />
        </Layout>
    );
}

export default Details